import { render, staticRenderFns } from "./MarketInformation.vue?vue&type=template&id=2baddd73&scoped=true&"
import script from "./MarketInformation.vue?vue&type=script&lang=js&"
export * from "./MarketInformation.vue?vue&type=script&lang=js&"
import style0 from "./MarketInformation.vue?vue&type=style&index=0&id=2baddd73&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2baddd73",
  null
  
)

export default component.exports